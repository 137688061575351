const dialog = require('./dialog');
const minicart = require('./minicart');
const searchsuggest = require('./searchsuggest');
const util = require('./util');
const ajax = require('./ajax');

/**
 * @function
 * @desc Initializes the textarea character limit functionality
 */
function initCharacterLimit() {
    const controlKeys = ['8', '9', '13', '46', '45', '36', '35', '38', '37', '40', '39'];

    $('body').on('keydown', 'textarea[data-character-limit], input[data-character-limit]', (e) => {
        const text = $.trim($(e.currentTarget).val());
        const charsLimit = $(e.currentTarget).data('character-limit');
        const charsUsed = text.length;

        if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
            e.preventDefault();
        }
    }).on('change keyup mouseup', 'textarea[data-character-limit], input[data-character-limit]', (e) => {
        const text = $(e.currentTarget).val();
        const charsLimit = $(e.currentTarget).data('character-limit');
        const charsUsed = text.length;

        let charsRemain = charsLimit - charsUsed;

        if (charsRemain < 0) {
            $(e.currentTarget).val(text.slice(0, charsRemain));
            charsRemain = 0;
        }
        $(e.currentTarget).parent().find('.char-count .char-remain-count').html(charsRemain);
    });

    // load js specific styles
    util.limitCharacters();
}

/**
 * @function
 * @desc Initializes the search suggestion functionality in the header
 */
function initSearchSuggestions() {
    /**
     * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
     * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
     * */
    const $searchContainer = $('.top-banner .header-search');
    searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH);
}

/**
 * @function
 * @desc Initializes the secondary navigation functionality
 */
function initSecondaryNavigation() {
    // add show/hide navigation elements
    $('.secondary-navigation .toggle').click((e) => {
        $(e.currentTarget).toggleClass('expanded').next('ul').toggle();
    });
}

/**
 * @function
 * @desc Initializes the generic toggle functionality
 */
function initToggle() {
    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();

    $('.toggle').on('click', (e) => {
        if (!$(e.currentTarget).parents('.disable-toggle').length) {
            $(e.currentTarget).toggleClass('expanded').next('.toggle-content').toggle();
        }
    });
}

/**
 * @function
 * @desc Initializes the generic check box functionality
 */
function initCheckBoxes() {
    // ensure checkbox values reflect checked state
    $('body').on('click', '.checkbox label', (e) => {
        util.setCheckboxValues($(e.currentTarget));
    });
}

/**
 * @function
 * @desc Initializes the email signup functionality
 */
function initEmailSignup() {
    // subscribe email box
    const $subscribeEmail = $('.subscribe-email');

    if ($subscribeEmail.length > 0) {
        $subscribeEmail.focus((e) => {
            const val = $(e.currentTarget).val();
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(e.currentTarget).animate({color: '#999999'}, 500, 'linear', function animateCallback() {
                $(this).val('').css('color', '#333333');
            });
        }).blur((e) => {
            const val = $.trim($(e.currentTarget).val());
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(e.currentTarget).val(Resources.SUBSCRIBE_EMAIL_DEFAULT).css('color', '#999999').animate({color: '#333333'}, 500, 'linear');
        });
    }
}

/**
 * @function loadCustomContentFiles
 * @desc handles custom content CSS and JS
 * @returns
 */
function loadCustomContentFiles() {
    if ($('.content-asset').length) {
        util.clearDynamicCss();
        util.clearDynamicJs();

        $('.content-asset').each((index, element) => {
            if ($(element).data('csspath')) {
                util.loadedCssFiles.push(util.loadCssFile($(element).data('csspath')));
            }
            if ($(element).data('jspath')) {
                util.loadedJsFiles.push(util.loadJsFile($(element).data('jspath')));
            }
        });
    }
}

/**
 * @function
 * @desc Initializes the general Open Modal functionality
 */
function initOpenModal() {
    $('body').on('click', '.openModal, .privacy-policy', (e) => {
        e.preventDefault();
        /// Checking for the page/asset link
        /// If it is not on the clicked item, check for a parent
        let $cParent; let
            href;
        if ($(e.target).attr('href')) {
            href = $(e.target).attr('href');
            $cParent = $(e.target);
        } else {
            $cParent = $(e.target);
            while (href === 'undefined' || !href) {
                $cParent = $cParent.parent();
                href = $cParent.attr('href');
            }
        }
        dialog.open({
            url: href,
            doClose: $('.pt_product-search-result').length === 0,
            options: {
                width: $cParent.data('dlg-width') ? $cParent.data('dlg-width') : '800',
                title: $cParent.data('dlg-title') ? $cParent.data('dlg-title') : $(e.currentTarget).attr('title'),
                dialogClass: $cParent.data('dlg-class'),
                open() {
                    // Check if a vertical scroll has been set and
                    // Fix the dialog scroll from starting at 1/2 way down
                    if ($('#dialog-container').css('overflow-y') === 'scroll' || $('#dialog-container').css('overflow-y') === 'auto') {
                        $('#dialog-container').scrollTop(0);
                    }
                    // load custom asset content
                    loadCustomContentFiles();
                },
            },
        });
    });
}

function initSocialDialog() {
    $('body').on('click', '.share-dialog', (e) => {
        e.preventDefault();
        const content = $(e.currentTarget).attr('href');

        dialog.open({
            html: content,
            options: {
                title: $(e.currentTarget).data('title'),
            },
        });
    });
}

/**
 * @function
 * @desc Initializes the mini cart functionality
 */
function initMiniCart() {
    // Minicart focus
    minicart.setFocus();
}

/**
 * @function
 * @desc Initializes the misc. header functionality
 */
function initHeader() {
    $(document).on('click', () => {
        $('html, #wrapper').removeClass('menu-active');
    });

    // main menu toggle
    $('.menu-toggle').on('click', (e) => {
        e.stopPropagation();
        $('html, #wrapper').toggleClass('menu-active');
    });

    $('.account-menu-toggle.registered').on('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        $('html, #wrapper').toggleClass('menu-active');
        $('.mobile-account-navigation a.registered').click();
    });

    $('#navigation').on('click', (e) => {
        const mobileMenuViewport = util.getViewports('mobile-menu') - 1;
        const mobileMenuMediaQuery = matchMedia(`(max-width: ${mobileMenuViewport}px)`);

        if (mobileMenuMediaQuery.matches) {
            e.stopPropagation();
        }
    });
}

/**
 * @function
 * @desc Initializes the misc. footer functionality
 */
function initFooter() {
    const emailSignupForm = $('#email-alert-signup');
    const emailInput = $('#email-alert-address');

    emailInput.on('focus', () => {
        emailSignupForm.next('.email-signup-msg').remove();
    });

    // Handles the submission of the email sign up in the footer
    emailSignupForm.submit((e) => {
        e.preventDefault();
        if (!emailSignupForm.valid()) {
            return false;
        }
        ajax.getJson({
            url: Urls.emailSignUpFooter,
            data: {
                email: emailInput.val(),
            },
            callback(data) {
                let msg; let
                    msgClass;
                if (data && data.success) {
                    emailInput.trigger('blur').val('');
                    msg = Resources.EMAIL_SIGNUP_SUCCESS;
                    msgClass = 'email-signup-msg';
                } else {
                    msg = Resources.EMAIL_SIGNUP_ERROR;
                    msgClass = 'email-signup-msg error';
                }
                // append/show result messaging
                let msgContainer = $('.email-signup-msg');
                if (msgContainer.length > 0) {
                    msgContainer.prop('class', '').addClass(msgClass).html(msg);
                } else {
                    msgContainer = $('<span/>').addClass(msgClass).html(msg);
                    emailSignupForm.after(msgContainer);
                }
            },
        });
        return true;
    });
}

function initNavPosition(target) {
    const desktopQuery = util.mediaBreakpointUp('mobile-menu');

    // restrict mouse events to desktop; affects iOS navigation
    if (desktopQuery) {
        if (!$(target).children('.level-2').isOnScreen(1, 0.5)) {
            $(target).children('.level-2').addClass('edge');
        }
    }
}

/**
 * @function
 * @desc Initializes the navigation functionality
 */
function initNavigation() {
    /**
     * @listener
     * @description Listens for the click event on the menu links to toggle the different levels on mobile
     */
    $('.menu-category a').on('click', (e) => {
        const mobileMenuViewport = util.getViewports('mobile-menu') - 1;
        const mobileMenuMediaQuery = matchMedia(`(max-width: ${mobileMenuViewport}px)`);
        let level;
        let nextLevel;
        let prevLevel;

        // Only trigger the menus if we are in the mobile menu viewport
        if (mobileMenuMediaQuery.matches) {
            // Trigger the sub menu if the chosen link has a sub menu
            if ($(e.currentTarget).hasClass('has-sub-menu')) {
                e.preventDefault();
                level = $(e.currentTarget).attr('data-level');
                nextLevel = Number(level) + 1;

                $('.menu-category a, .custom-navigation-item a, .sub-nav-content-asset, .mobile-menu-level-label').removeClass('show-menu-item');

                $(e.currentTarget).next().find(`[data-level="${nextLevel}"]`).addClass('show-menu-item');
                $(e.currentTarget).next().find('.sub-nav-content-asset').addClass('show-menu-item');

                // customer service menu is only visible in level-1
                $('.mobile-menu-utility-user').hide();
                $('.level-1 > li:last-child > a::after').show();

                // scroll to top
                window.scrollTo(0, 0);

            // Open the previous menu if the back link is clicked
            } else if ($(e.currentTarget).hasClass('back-link')) {
                e.preventDefault();
                level = $(e.currentTarget).attr('data-level');
                prevLevel = Number(level) - 1;

                $('.menu-category a, .custom-navigation-item a, .sub-nav-content-asset, .mobile-menu-level-label').removeClass('show-menu-item');

                if (prevLevel > 1) {
                    $(e.currentTarget).parents(`[data-container-level="${prevLevel}"]`).find(`[data-level="${prevLevel}"]`).addClass('show-menu-item');
                } else {
                    $(`[data-container-level="${prevLevel}"]`).find(`[data-level="${prevLevel}"]`).addClass('show-menu-item');
                    $('#navigation > .mobile-menu-level-label').addClass('show-menu-item');
                }

                if (prevLevel === 1) {
                    $('.mobile-menu-utility-user').show();
                    $('.level-1 > li:last-child > a::after').hide();
                }
            }
        }
    });

    // Mobile Account Menu for Registered Credit holders
    $('.mobile-account-navigation a.registered, .mobile-account-menu .back').on('click', (e) => {
        const mobileMenuViewport = util.getViewports('mobile-menu') - 1;
        const mobileMenuMediaQuery = matchMedia(`(max-width: ${mobileMenuViewport}px)`);

        // Only trigger the menus if we are in the mobile menu viewport
        if (mobileMenuMediaQuery.matches) {
            // Trigger the sub menu
            e.preventDefault();
            $('.mobile-account-navigation a').removeClass('show-menu-item');

            // hide category navigation
            $('.mobile-cap + .mobile-menu-level-label').removeClass('show-menu-item');
            $('.menu-category').hide();
            // hide customer service links
            $('.mobile-menu-utility-user').hide();

            // show account menu
            $('.mobile-account-menu.level-2').show();

            // Open the previous menu if the back link is clicked
            if ($(e.currentTarget).hasClass('back')) {
                e.preventDefault();

                // put back category navigation
                $('.mobile-cap + .mobile-menu-level-label').addClass('show-menu-item');
                $('.menu-category').show();
                // show other customer service links
                $('.mobile-menu-utility-user').show();

                // hide account menu
                $('.mobile-account-menu.level-2').hide();
            }

            // scroll to top
            window.scrollTo(0, 0);
        }
    });

    $('.account-nav-desktop-bottom .account-nav-toggle').on('click', (e) => {
        e.stopPropagation();
        $('.account-menu-toggle').click();
    });

    const navTimeout = window.SitePreferences.NAV_TIMEOUT;
    const navInterval = window.SitePreferences.NAV_INTERVAL;

    function navHover() {
        $(this).addClass('navActive');
        initNavPosition(this);
    }

    function navLeave() {
        $(this).removeClass('navActive');
    }

    $('#navigation').hoverIntent({
        over: navHover,
        out: navLeave,
        timeout: navTimeout,
        interval: navInterval,
        selector: '.level-1 li',
    });

    $('.menu-utility-user').hoverIntent({
        over: navHover,
        out: navLeave,
        timeout: navTimeout,
        interval: navInterval,
        selector: 'li',
    });

    util.smartResize(() => {
        $('.level-2').removeClass('edge');
    });
}

/**
 * @function
 * @desc Initializes the quantity module events and DOM changes
 */
function initQuantity() {
    /**
     * @listener
     * @desc Listens for the click and keyup events on the quantity decrease button
     */
    $('body').on('click keyup', '.quantity-decrease', (e) => {
        if (e.type === 'click' || (e.type === 'keyup' && e.which === 13)) {
            const $this = $(e.currentTarget);
            const qtyInput = $this.parents('.quantity-module').find('input');
            const qtyInputValue = Number(qtyInput.val());

            if ((qtyInputValue > 1)
                || ($this.parents('.product-set-list').length && qtyInputValue >= 0)
                || ($this.parents('.pnc').length && qtyInputValue > 0)) {
                qtyInput.val(qtyInputValue - 1);
                qtyInput.trigger('change');
            }
        }
    });

    /**
     * @listener
     * @desc Listens for the click and keyup events on the quantity increase button
     */
    $('body').on('click keyup', '.quantity-increase', (e) => {
        if (e.type === 'click' || (e.type === 'keyup' && e.which === 13)) {
            const qtyInput = $(e.currentTarget).parents('.quantity-module').find('input');
            const qtyInputValue = Number(qtyInput.val());
            const maxQtyValue = Number(qtyInput.attr('max'));
            const pnc = $('.pnc');

            // Do nothing if the quantity input is in the disabled state
            if (qtyInput.hasClass('disabled')) {
                e.preventDefault();
                return;
            }

            // Only increase the value if the current value is less than the max value
            if (qtyInputValue < maxQtyValue) {
                qtyInput.val(qtyInputValue + 1);
                qtyInput.trigger('change');
            } else if (pnc.length > 0 && qtyInput.hasClass('pnc-qty')) {
                const selectedQty = parseInt($('.pnc-remain-count').text(), 10);
                const totalQty = parseInt($('.pnc ').data('packcount'), 10);
                // Show errors when Pick 'n Choose max quantity limit reached
                if (selectedQty === totalQty) {
                    const url = util.appendParamsToUrl(Urls.pageShow, {cid: 'picknchoose-max-error', format: 'ajax'});
                    if (window.pageContext.ns === 'product') {
                        dialog.create({
                            target: $('#PickNChooseDialog'),
                            options: {
                                width: '700',
                            },
                        });
                        dialog.open({
                            url,
                            options: {
                                title: Resources.PRODUCT_ALERT,
                                buttons: [{
                                    text: Resources.OK,
                                    class: 'button primary',
                                    click() {
                                        $(this).dialog('close');
                                    },
                                }],
                            },
                        });
                    } else if (pnc.find('.pnc-error').length === 0) {
                        $('<div class="pnc-error"/>').appendTo(pnc).load(url);
                    }
                }
            }

            if (qtyInputValue === maxQtyValue) {
                $('.max-qty-msg').remove();
                $(e.currentTarget).parents('.item-quantity').children('.item-quantity-details')
                    .children('.product-availability-list')
                    .append(`<span class="max-qty-msg not-available">${Resources.CART_QUANTITY_RESTRICTION_MSG}</span>`);
            }
        }
    });

    /**
     * @listener
     * @desc Listens for the blur event on the quantity field
     */
    $('body').on('change', '.quantity-module input', (e) => {
        const qtyValue = Number($(e.currentTarget).val());
        const maxQtyValue = Number($(e.currentTarget).attr('max'));

        if (qtyValue < 0 || qtyValue === '') {
            if ($(e.currentTarget).parents('.product-set-list, .pnc').length) {
                $(e.currentTarget).val(0);
            } else {
                $(e.currentTarget).val(1);
            }
        } else if (qtyValue > maxQtyValue) {
            $(e.currentTarget).val(maxQtyValue);
        }
    });
}

/**
 * @function
 * @desc Initializes the sticky header functionality
 * @returns
 */
function initStickyHeader() {
    // Setup the sticky header functionality
    const $window = $(window);
    let lastWindowWidth = window.innerWidth;
    const scrollTriggerDesktop = 19;
    const scrollTriggerMobile = 19;
    const toggleStickyHeader = (e) => {
        const windowWidth = window.innerWidth;

        // Only true if the event came from a trigger call, if the event is of type
        // scroll, or only the width changed when the resize event occurs
        if (e.isTrigger === 3 || e.type === 'scroll' || lastWindowWidth !== windowWidth) {
            const scrollTop = $window.scrollTop();
            const desktopQuery = util.mediaBreakpointUp('md');
            let scrollTrigger;

            if (desktopQuery) {
                scrollTrigger = scrollTriggerDesktop;
            } else {
                scrollTrigger = scrollTriggerMobile;
            }

            if (scrollTop > scrollTrigger && !$('html').hasClass('sticky')) {
                $('html').addClass('sticky');
            } else if (scrollTop <= scrollTrigger) {
                $('html').removeClass('sticky');
            }

            lastWindowWidth = windowWidth;
        }
    };

    /**
     * @listener
     * @description Listens for the scroll and resize events on the window to toggle the sticky header when appropriate
     */
    $window.on('scroll.sticky resize.sticky', toggleStickyHeader).trigger('resize.sticky');
}

/**
 * @function
 * @desc Initializes the smooth scrolling to anchor
 */
function initScrollToAnchor() {
    $('#wrapper').on('click', 'a[href^="#"]', (e) => {
        const target = $(e.currentTarget).attr('href');
        if ($(target).length > 0) {
            $('html, body').animate({scrollTop: $(target).offset().top}, 400);
            return false;
        }
        return true;
    });
}

/**
 * @function
 * @desc Initializes the Back to top link
 * @returns
 */
function initBackToTop() {
    if ($('.back-to-top').length) {
        util.backToTop();

        $(window).scroll(() => {
            util.backToTop();
        });

        $('body').on('click', '.back-to-top', (e) => {
            e.preventDefault();
            $('html,body').animate({
                scrollTop: 0,
            }, 700);
        });
    }
}

$.fn.isOnScreen = function isOnScreen(x, y) {
    const xAxis = (x == null || typeof x === 'undefined') ? 1 : x;
    const yAxis = (y == null || typeof y === 'undefined') ? 1 : y;

    const win = $(window);

    const viewport = {
        top: win.scrollTop(),
        left: win.scrollLeft(),
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();

    const height = this.outerHeight();
    const width = this.outerWidth();

    if (!width || !height) {
        return false;
    }

    const bounds = this.offset();
    bounds.right = bounds.left + width;
    bounds.bottom = bounds.top + height;

    const visible = (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));

    if (!visible) {
        return false;
    }

    const deltas = {
        top: Math.min(1, (bounds.bottom - viewport.top) / height),
        bottom: Math.min(1, (viewport.bottom - bounds.top) / height),
        left: Math.min(1, (bounds.right - viewport.left) / width),
        right: Math.min(1, (viewport.right - bounds.left) / width),
    };

    return (deltas.left * deltas.right) >= xAxis && (deltas.top * deltas.bottom) >= yAxis;
};

function initHorizontalCarousel() {
    $('.horizontal-carousel .tiles-container').not('slick-initialized').each((index, element) => {
        const target = element;
        target.id = `slider${index}`;
        $(target).slick({
            dots: true,
            arrows: false,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            speed: SitePreferences.SLIDE_SHOW_SPEED,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            ],
        });
    });
    $('.horizontal-carousel-with-arrows .tiles-container').not('slick-initialized').each((index, element) => {
        const target = element;
        target.id = `slider${index}`;
        $(target).slick({
            dots: true,
            arrows: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            speed: SitePreferences.SLIDE_SHOW_SPEED,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            ],
        });
    });
}

function universalDrawers() {
    if ($('.select-payment-methods .universal-drawer').length > 1) {
        $('body').on('click', '.universal-drawer .drawer-handle', (e) => {
            e.preventDefault();
            const $drawer = $(e.currentTarget).parent();
            if ($drawer.hasClass('radio-treatment')) {
                if (!$drawer.hasClass('active')) {
                    $drawer.addClass('active');
                    $drawer.siblings().removeClass('active');
                }
            } else {
                $drawer.toggleClass('active');
            }
            $drawer.trigger('drawerClick');
        });
    } else {
        $('body').on('click', '.universal-drawer .drawer-handle', (e) => {
            e.preventDefault();
            const $drawer = $(e.currentTarget).parent();
            $drawer.toggleClass('active');
        });
    }

    $('body').on('click', '.universal-drawer .drawer-handle a', (e) => {
        e.preventDefault();
    });

    $('body').on('keydown', '.universal-drawer .drawer-handle', (e) => {
        if (e.which === 13) {
            e.preventDefault();
            $(e.currentTarget).trigger('click');
        }
    });
}

function stickItem() {
    const vpQuery = util.getViewports('md');
    $('body .sticky').pin({
        maxWidth: vpQuery,
        activeClass: 'pinned',
        padding: {
            top: 20,
        },
    });
}

/**
 * @function initTooltipClick
 * @desc stop the clicking of tooltip links
 * @returns
 */
function initTooltipClick() {
    // We don't want to allow using the a link for all tooltips
    // except the ones that have an open-modal link
    $('body').on('click', 'a.tooltip', (e) => {
        if (!$(e.currentTarget).hasClass('open-modal')) {
            e.preventDefault();
        }
    });
}

/**
 * @function ssnAutoTab
 * @desc tab to next ssn field automatically
 * @returns
 */
function ssnAutoTab() {
    $('input[name*="_ssn"]').keydown((e) => {
        const keys = [8, 9, 19, 20, 27, 33, 34, 35, 36, 37, 38, 39, 40, 45, 46, 48, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 144, 145];
        const charLimit = $(e.currentTarget).attr('maxlength');
        const $id = $(e.currentTarget).attr('id');

        if (e.which === 8 && e.currentTarget.value.length === 0) {
            if ($id.match(/_ssn_groupNumber/g)) {
                $('input[id*="_ssn_areaNumber"]').focus();
            }
            if ($id.match(/_ssn_serialNumber/g)) {
                $('input[id*="_ssn_groupNumber"]').focus();
            }
        } else if ($.inArray(e.which, keys) >= 0) {
            return true;
        } else if (e.currentTarget.value.length >= charLimit) {
            $(e.currentTarget).next('input[name*="_ssn"]').focus();
            if ($id.match(/_ssn_areaNumber/g)) {
                $('input[id*="_ssn_groupNumber"]').focus();
            }
            if ($id.match(/_ssn_groupNumber/g)) {
                $('input[id*="_ssn_serialNumber"]').focus();
            }
            return false;
        } else if (e.shiftKey || e.which <= 47 || e.which >= 58) {
            return false;
        }
        return true;
    }).keyup((e) => {
        const charLimit = $(e.currentTarget).attr('maxlength');
        if (e.currentTarget.value.length === charLimit) {
            const $id = $(e.currentTarget).attr('id');
            if ($id.match(/_ssn_areaNumber/g)) {
                $('input[id*="_ssn_groupNumber"]').focus();
            }
            if ($id.match(/_ssn_groupNumber/g)) {
                $('input[id*="_ssn_serialNumber"]').focus();
            }
            return false;
        }
        return true;
    });
}

module.exports = {
    init() {
        if (window.SitePreferences.ENABLE_STICKY_HEADER) {
            initStickyHeader();
        }
        initCharacterLimit();
        initSearchSuggestions();
        initSecondaryNavigation();
        initToggle();
        initCheckBoxes();
        initEmailSignup();
        initOpenModal();
        initMiniCart();
        initHeader();
        initFooter();
        initNavigation();
        initQuantity();
        initBackToTop();
        initHorizontalCarousel();
        initScrollToAnchor();
        universalDrawers();
        stickItem();
        initSocialDialog();
        loadCustomContentFiles();
        initTooltipClick();
        ssnAutoTab();
    },
};
